import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import './assets/style.scss';
import './assets/averta.scss' // Averta font import
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppWrapper from './AppWrapper';
import Loading from './shared/components/Loading/Loading';
import theme from './shared/theme'
import OpenIDContextProvider from './shared/components/OpenIDContextProvider/OpenIDContextProvider';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <OpenIDContextProvider>
    <React.StrictMode>
      <Suspense fallback={<Loading />}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppWrapper>
            <App />
          </AppWrapper>
        </ThemeProvider>
      </Suspense>
    </React.StrictMode>
  </OpenIDContextProvider>
);
reportWebVitals();
