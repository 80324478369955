import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as SecdimLogo } from "../../../assets/short-logo-without-beta.svg"
import { idRequestHandler } from "../../../api/requestHandler";
import { useOpenID } from "../../../api/queryHandlers";
import * as Styled from "./UserSettingsStyles"



function SSHKeyForm(props: SSHKeyUpdateProps) {
	const [isSignUpButtonLoading, setSignUpButtonLoading] = useState(false);
	const navigate = useNavigate();
	const openID = useOpenID();
	const accountDetails = JSON.parse(localStorage.getItem('accountDetails')!)

	async function handleGithubGitlabSSHKeys() {
		setSignUpButtonLoading(true)
		idRequestHandler(`${accountDetails && accountDetails["accountType"] === "Github" ? "retrieveGithubSSHKeys" : "retrieveGitlabSSHKeys"}`).then((resp) => {
			if (resp.status === 200) {
				sessionStorage.setItem("sshKeyList", JSON.stringify(resp.data.sshKeys!.split("\n")))
				if (accountDetails["accountType"] === "Github")
					navigate("/account/sshkey/github")
				else
					navigate("/account/sshkey/gitlab")
			}
		}).catch((err: AxiosError) => {
			console.error(err)
		})
	}

	function handleSSHKeySetupCancel() {
		localStorage.setItem('accountDetails', JSON.stringify({ "accountType": accountDetails["accountType"], "isAccountCreated": false }))
		if (openID) {
			sessionStorage.removeItem('openid')
			window.location.href = `${process.env.REACT_APP_BASE_API_URL}openid/authorize/?${openID.client_id}&redirect_uri=${openID.redirect_uri}&scope=${openID.scope}&response_type=${openID.response_type}&response_mode=query&nonce=${openID.nonce}&state=${openID.state}`;
		}
		else {
			navigate("/account/settings")
		}
	}

	return (
		<form id="sshkeyform" className={props.className}>
			<Styled.CustomPaper>
				<Box p={3}>
					<Box mt={0} display='flex' justifyContent="center" pr={2}>
						<SecdimLogo height={40} width={40} className="secdimlogo mr-2" onClick={() => navigate("/account/settings")} />
						<Typography id="signInText" variant="h6" component="h1" className="secdimlogo">SSH Key</Typography>
					</Box>
					<Box mt={5} />
					{
						accountDetails && (accountDetails["accountType"] === "Github" || accountDetails["accountType"] === "Gitlab") ? <Box my={2} mt={3} sx={{ cursor: 'pointer' }}>
							{isSignUpButtonLoading ? <Styled.CustomLoadingButton onClick={handleGithubGitlabSSHKeys} loading>
								<Typography>Please Wait</Typography>
							</Styled.CustomLoadingButton> : <Styled.ActionButton fullWidth variant="contained" onClick={handleGithubGitlabSSHKeys}>
								<Box py={2}>
									IMPORT {accountDetails["accountType"] === "Github" ? "GITHUB" : (accountDetails["accountType"] === "Gitlab" && "GITLAB")} SSH KEY
								</Box>
							</Styled.ActionButton>}
						</Box> : <></>
					}
					<Box my={2} mt={3}>
						<Styled.ActionButton fullWidth variant="contained" onClick={() => navigate("/account/sshkey/change")}>
							<Box py={2}>
								ADD OR UPDATE SSH KEY
							</Box>
						</Styled.ActionButton>
					</Box>
					{/* {
            accountDetails && !accountDetails["isAccountCreated"] ? <Box my={2} mt={3}>
            <Styled.ActionButton fullWidth variant="contained" onClick={() => handleSSHDelete()}>
              <Box py={2}>
                DELETE SSH KEY
              </Box>
            </Styled.ActionButton>
            {hasFailed ?
              <div>
                <Box mt={3} display='flex' justifyContent="center" pr={2}>
                  <Typography id="ErrorText" >DELETION FAILED</Typography>
                </Box>
              </div>
              :
              <div></div>
            }
          </Box> : <></>
          } */}
					<Box my={2} mt={3}>
						<Styled.ActionButton invert fullWidth variant="contained" onClick={handleSSHKeySetupCancel}>
							<Box py={2}>
								CANCEL
							</Box>
						</Styled.ActionButton>
					</Box>
				</Box>
			</Styled.CustomPaper>
		</form>
	)
}
export default styled(SSHKeyForm)`
.ErrorText {
  color: #ff0000 !important;
}
.secdimlogo {
  vertical-align: middle;
  display: inline-block;
}
.mr-2 {
  margin-right: 10px;
}
.flex-1 {
  flex: 1;
}
.translateBack {
  transform: translateX(-25px)
}
#ErrorText {
  color: #ff0000 !important;
}
#signInText {
  font-weight: 400 !important;
  margin-top: 5px;
}
#signinbtn {
  border: 1px solid #55C1E2;
  background-color: #55C1E2 !important;
  color: #FFF !important;
} 

.secdimLink {
  color: #55C1E2 !important;
  cursor: pointer;
}

#cancelbtn {
  border: 1px solid #55C1E2;
  background-color: #FFF !important;
  color: #55C1E2 !important;
}

@media (max-width: 500px) {
  .translateBack {
    transform: initial !important;
  }
}
`;



