import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

export const FooterContainer = styled('footer')(({ theme }) => ({
	width: '100%',
	marginTop: 'auto',
	padding: '24px 0px 8px',
	borderTop: '2px solid #E1E1E1',

	[theme.breakpoints.down('md')]: {
		padding: '24px 0 0',
	},
}))

export const FooterInnerContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	flexWrap: 'nowrap',
	gap: 8,

	[theme.breakpoints.down('md')]: {
		flexDirection: 'column',
	},
}))

export const FooterCopyrightContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexBasis: '40%',

	[theme.breakpoints.down('md')]: {
		order: 3,
		flexBasis: '100%',
	},
}))

export const FooterLogoContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexBasis: '20%',

	[theme.breakpoints.down('md')]: {
		order: 1,
		flexBasis: '100%',
	},
}))

export const FooterLogo = styled('a')({
	maxWidth: 145,
	margin: 'auto',

	'& > img': {
		width: '100%',
	},
})

export const FooterNav = styled(Grid)(({ theme }) => ({
	display: 'flex',
	flexBasis: '40%',
	justifyContent: 'space-between',
	alignSelf: 'center',
	gap: 16,

	[theme.breakpoints.up('lg')]: {
		paddingLeft: '10%',
	},

	[theme.breakpoints.up('md')]: {
		paddingLeft: '5%',
	},

	[theme.breakpoints.down('md')]: {
		order: 2,
		flexBasis: '100%',
	},

	[theme.breakpoints.between('sm', 'md')]: {
		width: '100%',
	},
}))

export const FooterNavItem = styled('a')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	color: theme.palette.text.secondary,
	textDecoration: 'none',

	'&:hover': {
		color: theme.palette.primary.main,
	},

	[theme.breakpoints.between('sm', 'md')]: {
		flexBasis: '33.333%',
	},
}))
