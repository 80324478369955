// @ts-nocheck
import { createTheme } from '@mui/material/styles' // createMuiTheme is deprecated but createTheme doesnt have many guides yet for this scenario
import AvertaWoff from '../assets/fonts/averta-regular-webfont.woff'
import AvertaBoldWoff from '../assets/fonts/averta-bold-webfont.woff'
import AvertaSemiBoldWoff from '../assets/fonts/averta-semibold-webfont.woff'

const averta = {
	fontFamily: 'Averta',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `
    url(${AvertaWoff}) format('woff')
  `,
}

const avertaSemiBold = {
	fontFamily: 'Averta',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 600,
	src: `
    url(${AvertaSemiBoldWoff}) format('woff')
  `,
}

const avertaBold = {
	fontFamily: 'Averta',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 'bold',
	src: `
    url(${AvertaBoldWoff}) format('woff')
  `,
}

/* eslint-disable */
declare module '@mui/material/styles' {
	interface BreakpointOverrides {
		xs: true;
		ss: true;
		smm: true;
		sm: true;
		md: true;
		mdm: true;
		lg: true;
		xl: true;
		hr: true; // Add this breakpoint
		k4: true;
	}
}
/* eslint-enable */

export default createTheme({

	breakpoints: {
		values: {
			xs: 0,
			ss: 350,
			smm: 450,
			sm: 600,
			md: 900,
			mdm: 1050,
			lg: 1200,
			xl: 1536,
			hr: 2000,
			k4: 3100,
		},
	},

	typography: {
		fontSize: 16,
		body1: {
			fontSize: '1rem',
			lineHeight: 1.7,
		},
		body2: {
			fontSize: '1.125rem',
			lineHeight: 1.7,
		},
		subtitle1: {
			fontSize: '1rem',
			lineHeight: 1.857,
			color: 'grey',
		},
		subtitle2: {
			fontSize: '1rem',
			lineHeight: 1.857,
			color: '#55C1E2',
		},
		h1: {
			fontSize: '3.5rem',
			// @ts-ignore
			fontWeight: '600',
			color: '#424242',
		},
		h2: {
			fontSize: '3rem',
			color: '#55C1E2',
		},
		h3: {
			fontSize: '2.5rem',
		},
		h4: {
			fontSize: '1.875rem'
		},
		h5: {
			fontSize: '1.5rem'
		},
		h6: {
			fontSize: '1.25rem',
			lineHeight: 1.6,
		},
		caption: {
			color: 'black',
			fontSize: '1rem'
		}
	},
	palette: {
		primary: {
			main: '#55C1E2', // secdim blue
			dark: '#34b5dc', // secdim blue but darker
			light: '#424242', // dark grey
			contrastText: "#e3f7fc", // secdim blue with transparency
		},
		secondary: {
			light: '#fff', // white
			main: '#000000', // black
		},
		error: {
			main: '#FF0000', // red
			dark: '#FF9494', // light-red
			contrastText: '#FF400022',
		},
		success: {
			main: '#007300', // lime
			dark: '#00FF00', // green
			contrastText: '#b0e46322',
		},
		text: {
			secondary: '#919191', // grey
		},
		common: {
			white: '#fff', // white
			black: '#000', // red
		},
		warning: {
			main: '#FFA500', // orange
		},
		info: {
			main: '#000000' // black
		}
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
        		.full-height {
          			height: 100%;
        		}
      		`,
		},
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				'@font-face': [averta, avertaSemiBold, avertaBold],
				body: {
					backgroundColor: '#fff',
					fontSize: '1rem',
					lineHeight: 1.7,
				},
			},
		},
		MuiButton: {
			containedPrimary: {
				color: '#fff',
			},
		},
		MuiFab: {
			primary: {
				color: '#fff',
			},
		},
	},
})
