import { Box, Button, Typography } from "@mui/material";
import styled from "styled-components";
import { CheckCircleOutline } from "@mui/icons-material";
import { useOpenID } from "../../../api/queryHandlers";
import * as Styled from "./UserSettingsStyles"

function GenericSuccessForm(props: GenericSuccessProps) {
  const openID = useOpenID();
  function handleButtonReaction() {
    if (openID) {
      sessionStorage.removeItem('openid')
      window.location.href = `${process.env.REACT_APP_BASE_API_URL}openid/authorize/?${openID.client_id}&redirect_uri=${openID.redirect_uri}&scope=${openID.scope}&response_type=${openID.response_type}&response_mode=query&nonce=${openID.nonce}&state=${openID.state}`;
    }
    else {
      window.location.href = props.link
    }
  }
  return (
    <Box justifyContent="center" width="100%" className={props.className} display="flex">
      <Styled.CustomPaper>
        <Box py={5} className="secdimBlueBG">
          <Box display="flex" justifyContent="center">
            <CheckCircleOutline className="bigCheck whiteText" />
          </Box>
          <Typography variant="h4" className="notBold whiteText" align="center">SUCCESS</Typography>
        </Box>
        <Box p={3}>
          <Box mt={0} display='flex' justifyContent="center" pr={2}>
            <Typography id="signInText" variant="h6" component="h1" className="secdimlogo">{props.successText}</Typography>
          </Box>
          <Box mt={4} />
          <Box my={2} mt={3}>
            <Button fullWidth variant="contained" id="signinbtn" onClick={handleButtonReaction}>
              <Box py={2}>
                {props.buttonText}
              </Box>
            </Button>
          </Box>
        </Box>
      </Styled.CustomPaper>
    </Box>
  )
}
export default styled(GenericSuccessForm)`
.bigCheck {
  font-size: 100px;
}
.whiteText {
  color: #FFF !important;
}
.secdimBlueBG {
  background-color: #55C1E2 !important;
}
.secdimlogo {
  vertical-align: middle;
  display: inline-block;
}
.mr-2 {
  margin-right: 10px;
}
.flex-1 {
  flex: 1;
}
.translateBack {
  transform: translateX(-25px)
}
#ErrorText {
  color: #ff0000 !important;
}
#signInText {
  font-weight: 400 !important;
  text-align: center;
}
#signinbtn {
  background-color: #55C1E2 !important;
  color: #FFF !important;
}
.secdimLink {
  color: #55C1E2 !important;
  cursor: pointer;
}
@media (max-width: 500px) {
  .translateBack {
    transform: initial !important;
  }
}
`;


