import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";
import { ReactComponent as SecdimLogo } from "../../../../assets/short-logo-without-beta.svg"
import { useOpenID } from "../../../../api/queryHandlers";
import * as Styled from "./SSHKeyStyles"

function SSHKeyChangeForm(props: SSHKeyUpdateProps) {

  const navigate = useNavigate();
  const [key, setKey] = useState("");
  const [iskeyvalid, setIskeyvalid] = useState(true);
  const [keySubmitted, setKeySubmitted] = useState(false);
  const hasFailed = props.hasFailed;
  const accountDetails = JSON.parse(localStorage.getItem('accountDetails')!)
  const openID = useOpenID();

  async function handleSSHUpdate() {

    let regex = /^(ssh-rsa AAAAB3NzaC1yc2|ecdsa-sha2-nistp256 AAAAE2VjZHNhLXNoYTItbmlzdHAyNT|ecdsa-sha2-nistp384 AAAAE2VjZHNhLXNoYTItbmlzdHAzODQAAAAIbmlzdHAzOD|ecdsa-sha2-nistp521 AAAAE2VjZHNhLXNoYTItbmlzdHA1MjEAAAAIbmlzdHA1Mj|ssh-ed25519 AAAAC3NzaC1lZDI1NTE5|ssh-dss AAAAB3NzaC1kc3)[0-9A-Za-z+/]+[=]{0,3}( .*)?$/i;

    if (key === "" || !(regex.test(key))) { setIskeyvalid(false); } // If key is either empty or fails regex test, its false
    else { // Otherwise its valid
      setIskeyvalid(true);
      setKeySubmitted(true);
      props.handler({ key });
    }
  }

  //TODO: Key Validation from BackEnd

  const onKeyPress =
    (e: any) => {
      if (e.key === "Enter") {
        handleSSHUpdate();
        e.preventDefault();
      }
    }

  function handleSshKeyAddOrUpdateCancel() {
    if (accountDetails && accountDetails["accountType"] === "manual" && accountDetails["isAccountCreated"] && openID) {
      localStorage.setItem('accountDetails', JSON.stringify({ "accountType": accountDetails["accountType"], "isAccountCreated": false }))
      sessionStorage.removeItem('openid')
      window.location.href = `${process.env.REACT_APP_BASE_API_URL}openid/authorize/?${openID.client_id}&redirect_uri=${openID.redirect_uri}&scope=${openID.scope}&response_type=${openID.response_type}&response_mode=query&nonce=${openID.nonce}&state=${openID.state}`;
    }
    else {
      navigate(`/account/sshkey`)
    }
  }

  return (
    <form id="sshkeyform" className={props.className}>
      <Styled.CustomPaper>
        <Box p={3}>
          <Box mt={0} display='flex' justifyContent="center" pr={2}>
            <SecdimLogo height={40} width={40} className="secdimlogo mr-2" onClick={() => navigate("/account/settings")} />
            <Typography id="signInText" variant="h6" component="h1" className="secdimlogo">SSH Public Key</Typography>
          </Box>
          <Box mt={5} mb={4}>
            <Box className="step">
              <>
                <Box className="stepNumber">1</Box>
                <Typography variant="h6" component="h6" className="instruction" align="left"> Generate a pair of default SSH keys </Typography>
                <Typography align="left" className="commandLine"> {'>'} ssh-keygen -t ed25519 -f ~/.ssh/id_ed25519</Typography>
              </>
            </Box>
            <Box className="step">
              <>
                <Box className="stepNumber">2</Box>
                <Typography variant="h6" className="instruction" component="h6" align="left"> Copy content of your default SSH public key</Typography>
                <Typography align="left" className="commandLine"> {'>'} cat ~/.ssh/id_ed25519.pub</Typography>
              </>
            </Box>
            <Box className="step">
              <Box className="stepNumber">3</Box>
              <Typography variant="h6" component="h6" align="left" className="instruction"> Paste content of your SSH public key below and Save </Typography>
            <Box>
              <Styled.CssTextField error={hasFailed || !iskeyvalid} multiline={true} rows={10} variant="outlined" fullWidth placeholder="Copy your SSH Public Key here" value={key} onChange={(e: any) => setKey(e.target.value)} onKeyPress={onKeyPress} />
                {(hasFailed || !iskeyvalid) &&
                  <Styled.CustomAlert severity='error'>
                    <Styled.CustomAlertTitle>ERROR</Styled.CustomAlertTitle>
                    Please ensure the SSH key is valid.
                  </Styled.CustomAlert>
                }
                {(iskeyvalid && keySubmitted && !props.hasFailed) &&
                  <Styled.CustomAlert severity='success'>
                    <Styled.CustomAlertTitle>SUCCESS</Styled.CustomAlertTitle>
                    SSH key successfully updated!
                  </Styled.CustomAlert>
                }
            </Box>
            </Box>
            <Box className="step">
              <>
                <Box className="stepNumber">4</Box>
                <Typography variant="h6" component="h6" align="left" className="instruction"> Test your connection  </Typography>
                <Typography align="left" className="commandLine"> {'>'} ssh -T git@game.secdim.com</Typography>
              </>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="center" sx={{ margin: '32px 0px 20px' }}>
            {iskeyvalid && keySubmitted && !props.hasFailed ?
              <Styled.ActionButton fullWidth variant="contained" onClick={() => navigate(`/account/settings`)}>
                <Box p={1}>
                  SETTINGS
                </Box>
              </Styled.ActionButton>
              :
              <Styled.ActionButton fullWidth variant="contained" onClick={() => handleSSHUpdate()}>
                <Box p={1}>
                  SAVE
                </Box>
              </Styled.ActionButton>
            }
            <Box px={2} />
            <Styled.ActionButton invert fullWidth variant="contained" onClick={() => handleSshKeyAddOrUpdateCancel()}>
              <Box p={1}>
                CANCEL
              </Box>
            </Styled.ActionButton>
          </Box>
        </Box>
      </Styled.CustomPaper>
    </form>
  )
}
export default styled(SSHKeyChangeForm)`
.step {
  margin-bottom: 10px;
  font-weight: bold;
}
.instruction {
  margin-left: 35px;
}
.stepNumber {
  width: 23px;
  height: 23px;
  float: left;
  background-color: #55C1E2 !important;
  color: #FFF !important;
  border-radius: 50%;
  margin-top: 5px;
  text-align: center;
}
.commandLine {
  color: #ff0000de !important;
  margin-left: 35px;
}
.secdimlogo {
  vertical-align: middle;
  display: inline-block;
}
.mr-2 {
  margin-right: 10px;
}
.flex-1 {
  flex: 1;
}
.translateBack {
  transform: translateX(-25px)
}
#ErrorText {
  color: #ff0000 !important;
  font-weight: 100 !important;
  text-align: left;
  font-size: medium;
}
#signInText {
  font-weight: 400 !important;
  margin-top: 5px;
}
#signinbtn {
  margin-right: 10px !important;
  border: 1px solid #55C1E2 !important;
  background-color: #55C1E2 !important;
  color: #FFF !important;
  padding: 10px 16px !important;
}
#cancelbtn {
  margin-left: 10px !important;
  background-color: #fff !important;
  color: #55C1E2 !important;
  border: 1px solid #55C1E2 !important;
  padding: 10px 16px !important;
}
.secdimLink {
  color: #55C1E2 !important;
  cursor: pointer;
}
@media (max-width: 500px) {
  .translateBack {
    transform: initial !important;
  }
}
`;

/* Material UI Styling */

// const CssTextField = withStyles({
//   root: {
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderColor: '#C4C4C4',
//       },
//       '&:hover fieldset': {
//         borderColor: '#000000',
//       },
//       '&.Mui-focused fieldset': {
//         borderColor: '#55C1E2',
//       },
//     },
//   },
// })(TextField);

// const CssErrorTextField = withStyles({
//   root: {
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderColor: '#ff0000',
//       },
//       '&:hover fieldset': {
//         borderColor: '#ff0000',
//       },
//       '&.Mui-focused fieldset': {
//         borderColor: '#ff0000',
//       },
//     },
//   },
// })(TextField);
