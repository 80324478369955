import { useState, useEffect, useMemo } from 'react'
import { idRequestHandler } from '../../../api/requestHandler';
import { UserContext } from '../../../api/hooks';
import React from 'react';

function OpenIDContextProvider({ className, children }: DefaultProps) {
	const [profile, setProfile] = useState({
		hasResponded: false,
		isAuthenticated: false,
		username: '',
		isSubscribed: false,
	})
	useEffect(() => {
		if (!document.location.pathname.includes("logout")) {
			idRequestHandler('heartbeat').then(hbResp1 => {
				if (hbResp1.data.logged_in) {
					idRequestHandler('userSubscriptionInfo').then(userSubscriptionInfoResp => {
						if (userSubscriptionInfoResp.status === 200) {
							setProfile({
								hasResponded: true,
								isAuthenticated: true,
								username: userSubscriptionInfoResp.data.username,
								isSubscribed: userSubscriptionInfoResp.data.is_subscribed
							})
						}
					}).catch(() => { })
				} else {
					setProfile({
						hasResponded: true,
						isAuthenticated: false,
						username: '',
						isSubscribed: false,
					});
				}
			}).catch(() => { })
		}
	}, [])

	const valueOfOpenIDContext = useMemo(() => ({
		profile,
		setProfile
	}), [profile]);

	return (
		<div className={className}>
			<UserContext.Provider
				value={valueOfOpenIDContext}
			>
				{children}
			</UserContext.Provider>
		</div>
	)
}
export default React.memo(OpenIDContextProvider)
