import { Box, IconButton, Typography, InputAdornment, Alert } from "@mui/material";
import { Visibility, VisibilityOff, Check, Close } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SecdimLogo } from "../../../assets/short-logo-without-beta.svg"
import * as Styled from "./UserSettingsStyles"

function SettingsForm(props: CPasswordProps) {
	const navigate = useNavigate();

	const regex = /\d/g;
	const format = /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]+/;
	const [password, setPassword] = useState("");
	const [passwordRepeat, setPasswordRepeat] = useState("");
	const [passwordOld, setPasswordOld] = useState("");
	const [showpassRepeat, setShowpassRepeat] = useState(false);
	const [showpass, setShowpass] = useState(false);
	const [showpassOld, setShowpassOld] = useState(false);
	const [isInvalidPassword, setIsInvalidPassword] = useState(false);
	const [isInvalidPasswordRepeat, setIsInvalidPasswordRepeat] = useState(false);
	const hasFailed = (props.error !== ""); // if the error message is empty then there is no failure

	// TODO: COMMENT CLEAN UP
	// Password Strength checker
	const [isPasswordStrengthCheckerShown, setPasswordStrengthCheckerShown] = useState(false);
	const [hasLowercase, setHasLowercase] = useState(false);
	const [hasUppercase, setHasUppercase] = useState(false);
	const [hasSpecial, setHasSpecial] = useState(false);
	const [hasNumber, setHasNumber] = useState(false);
	const [hasEight, setHasTen] = useState(false);

	// Functions
	// TODO: Handle Comments
	function handleChangePass() {
		if ((password.toLowerCase() !== password) && (password.toUpperCase() !== password) && regex.test(password) && password.length > 7 && format.test(password)) {
			setIsInvalidPassword(false); // Check if the password is strong enuf otherwise stop and flag
			if (password === passwordRepeat) { // Does the Password Re entry match?
				setIsInvalidPasswordRepeat(false); // yes

				// Password has a lowercase, uppercase, number, special symbol and a length greater than 7 
				// Password was also repeated correctly
				// Attempt to change password

				props.handler({
					new_password: password,
					old_password: passwordOld
				})

			} else {
				setIsInvalidPasswordRepeat(true); // password repeat failed
			}
		} else {
			setIsInvalidPassword(true); // Password ain't strong enuf
			setPasswordOld("");
			setPassword("");
			setPasswordRepeat("");
		}
	}

	function updatePass(e: string) {
		setPassword(e) // Default function for updating password
		strengthCheck(e); // Now check password strength after updating
	}

	function strengthCheck(password: string) {
		if (password.toUpperCase() !== password) { setHasLowercase(true); } else { setHasLowercase(false); }
		if (password.toLowerCase() !== password) { setHasUppercase(true); } else { setHasUppercase(false); }
		if (regex.test(password)) { setHasNumber(true); } else { setHasNumber(false); }
		if (password.length > 9) { setHasTen(true); } else { setHasTen(false); }
		if (format.test(password)) { setHasSpecial(true); } else { setHasSpecial(false); }
	}

	const onBlurPasswordField = (event: any) => {
		setPasswordStrengthCheckerShown(false)
	};

	const onFocusPasswordField = (event: any) => {
		setPasswordStrengthCheckerShown(true)
	};

	const showPasswordAdorn = <InputAdornment position="end"><IconButton aria-label="toggle password visibility" onClick={() => setShowpass(true)} onMouseDown={() => setShowpass(false)} edge="end">{showpass ? <Visibility /> : <VisibilityOff />}</IconButton></InputAdornment>
	const showPasswordAdornRepeat = <InputAdornment position="end"><IconButton aria-label="toggle password visibility" onClick={() => setShowpassRepeat(true)} onMouseDown={() => setShowpassRepeat(false)} edge="end">{showpassRepeat ? <Visibility /> : <VisibilityOff />}</IconButton></InputAdornment>
	const showPasswordAdornOld = <InputAdornment position="end"><IconButton aria-label="toggle password visibility" onClick={() => setShowpassOld(true)} onMouseDown={() => setShowpassOld(false)} edge="end">{showpassOld ? <Visibility /> : <VisibilityOff />}</IconButton></InputAdornment>
	const onKeyPress = (e: any) => { if (e.key === "Enter") { handleChangePass(); e.preventDefault(); } }
	return (
		<form id="passwordsettingsform" className={props.className}>
			<Styled.CustomPaper>
				<Box p={3}>
					<Box mt={0} mb={4} display='flex' justifyContent="center" pr={2}>
						<SecdimLogo height={40} width={40} className="secdimlogo mr-2" onClick={() => navigate("/account/settings")} />
						<Typography id="signInText" marginLeft="10px" fontWeight="400" variant="h6" component="h1" className="secdimlogo" >Change Password</Typography>
					</Box>
					<Box my={1}>
						<Styled.CssTextField
							error={hasFailed}
							variant="outlined" color="primary"
							fullWidth label="Old Password"
							type={showpassOld ? "text" : "password"} value={passwordOld}
							onChange={(e: any) => setPasswordOld(e.target.value)}
							onKeyPress={onKeyPress}
							InputProps={{
								endAdornment: showPasswordAdornOld,
							}} />
					</Box>
					<Box my={2}>
						<Styled.CssTextField
							error={isInvalidPassword || isInvalidPasswordRepeat}
							variant="outlined" color="primary"
							fullWidth label="Password"
							type={showpass ? "text" : "password"} value={password}
							onChange={(e: any) => updatePass(e.target.value)}
							onKeyPress={onKeyPress}
							onBlur={onBlurPasswordField}
							onFocus={onFocusPasswordField}
							InputProps={{
								endAdornment: showPasswordAdorn,
							}} />
						{(isInvalidPassword) && <Styled.InfoText isError variant="h6" align="left">
							Password is not Strong Enough!
						</Styled.InfoText>}
					</Box>
					<Box my={2}>
						<Styled.CssTextField
							error={isInvalidPasswordRepeat}
							variant="outlined" color="primary"
							fullWidth label="Repeat Password"
							type={showpassRepeat ? "text" : "password"} value={passwordRepeat}
							onChange={(e: any) => setPasswordRepeat(e.target.value)}
							onKeyPress={onKeyPress}
							InputProps={{
								endAdornment: showPasswordAdornRepeat,
							}} />
						{(isInvalidPasswordRepeat) && <Styled.InfoText isError variant="h6" align="left">
							Passwords do not match!
						</Styled.InfoText>}
					</Box>
					{isPasswordStrengthCheckerShown ? <Box>
						<Styled.PasswordStrength isChecked={hasLowercase}>{hasLowercase ? <Check /> : <Close />} <Styled.PasswordStrengthText> One Lowercase Character </Styled.PasswordStrengthText></Styled.PasswordStrength>
						<Styled.PasswordStrength isChecked={hasUppercase}>{hasUppercase ? <Check /> : <Close />} <Styled.PasswordStrengthText> One Uppercase Character </Styled.PasswordStrengthText></Styled.PasswordStrength>
						<Styled.PasswordStrength isChecked={hasSpecial}>{hasSpecial ? <Check /> : <Close />} <Styled.PasswordStrengthText> One Special Character </Styled.PasswordStrengthText></Styled.PasswordStrength>
						<Styled.PasswordStrength isChecked={hasNumber}>{hasNumber ? <Check /> : <Close />} <Styled.PasswordStrengthText> One Number </Styled.PasswordStrengthText></Styled.PasswordStrength>
						<Styled.PasswordStrength isChecked={hasEight}>{hasEight ? <Check /> : <Close />} <Styled.PasswordStrengthText> At Least 10 Characters </Styled.PasswordStrengthText></Styled.PasswordStrength>
					</Box> : <></>}
					<Box>
						{hasFailed ?
							<Alert severity='error' sx={{ margin: '24px 0px 12px 0px' }}><Box mt={-0.5}>{props.error}</Box></Alert>
							:
							<div><Styled.InfoText isError>{props.error}</Styled.InfoText></div>
						}
					</Box>
					<Box my={2} mt={3} >
						<Styled.ActionButton fullWidth variant="contained" onClick={() => handleChangePass()}>
							<Box py={2}>
								SAVE
							</Box>
						</Styled.ActionButton>
					</Box>
					<Box my={2} mt={3} >
						<Styled.ActionButton invert fullWidth variant="contained" onClick={() => navigate("/account/settings")}>
							<Box py={2}>
								CANCEL
							</Box>
						</Styled.ActionButton>
					</Box>
				</Box>
			</Styled.CustomPaper>
		</form>
	)
}
export default SettingsForm;

/* Material UI Styling */

// const CssInput = withStyles({
//   root: {
//     '&.MuiOutlinedInput-root': {
//       '&.Mui-focused fieldset': {
//         borderColor: '#55C1E2',
//       }
//     },
//   },
// })(OutlinedInput);

// const CssErrorInput = withStyles({
//   root: {
//     '&.MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderColor: '#ff0000',
//       },
//       '&:hover fieldset': {
//         borderColor: '#ff0000',
//       },
//       '&.Mui-focused fieldset': {
//         borderColor: '#ff0000',
//       }
//     },
//   },
// })(OutlinedInput);
/**
 * .secdimlogo {
  vertical-align: middle;
  display: inline-block;
}
.mr-2 {
  margin-right: 10px;
}
.flex-1 {
  flex: 1;
}
.translateBack {
  transform: translateX(-25px)
}
#ErrorText {
  color: #ff0000 !important;
  text-align: left;
  font-size: medium;
  font-weight: 100 !important;
}
#signInText {
  font-weight: 400 !important;
}
#signinbtn {
  border: 1px solid #55C1E2 !important;
  background-color: #55C1E2 !important;
  color: #FFF !important;
}
#cancelbtn {
  background-color: #fff !important;
  color: #55C1E2 !important;
  border: 1px solid #55C1E2 !important;
}
.secdimLink {
  color: #55C1E2 !important;
  cursor: pointer;
}
@media (max-width: 500px) {
  .translateBack {
	transform: initial !important;
  }
}
`
 */



