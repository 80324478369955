import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUserContext } from "../../api/hooks";
import { comprehendDjangoError } from "../../api/queryHandlers";
import { idRequestHandler } from "../../api/requestHandler";
import SettingsForm from "../../components/user/settings/SettingsForm";
import FormWrapper from "../FormWrapper";



function Settings(props: DefaultProps) {
	const navigate = useNavigate()
	// eslint-disable-next-line
	const [errorText, setErrorText] = useState("");
	const { profile } = useUserContext()
	let redirectURI = useRef(sessionStorage.getItem("redirectURI") ?? "");
	
	useEffect(() => {
		if (profile.hasResponded && !profile.isAuthenticated) {
			navigate("/account/login");
		}
		if (profile.hasResponded && profile.isAuthenticated && redirectURI.current) {
			sessionStorage.removeItem("redirectURI");
			window.location.href = redirectURI.current;
		}
	}, [profile.hasResponded, profile.isAuthenticated, navigate]);
  
	async function createPortalSession() {
		try {
			if (profile.isAuthenticated) {
				const resp = await idRequestHandler("createPortalSession", { })
				if (resp.status === 201) {
					window.location.href = resp.data.url
				} else {
					setErrorText(JSON.stringify(resp.data))
				}
			}
			else {
				navigate("/account/login")
			}

		} catch (e) {
			const err = e as AxiosError
			const reason = comprehendDjangoError(err.response?.data)
			setErrorText(reason)
		}
	}

	async function handleSubscriptionRedirect() {
		if (profile.isSubscribed) {
			// User is subscribed
			createPortalSession();
		}
		else {
			// User is not subscribed
			navigate('/subscription/plan')
		}
	}

	return <>
		{
			profile.isAuthenticated && !redirectURI.current &&
			<div id="settingspage" className={props.className}>
				<FormWrapper>
					<SettingsForm username={profile.username} handleSubscriptionRedirect={handleSubscriptionRedirect} />
				</FormWrapper>
			</div>
		}
	</>
}
export default styled(Settings)`
.card {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.card-helper {
    display: inline-block;
    height: 70px;
    vertical-align: middle;
}
.helper {
    display: inline-block;
    height: 100px;
    vertical-align: middle;
}
@media (max-width: 600px) {
    .helper {
        display: none;
        height: 0;
    }
}
`;