import styled from "styled-components";
import GenericSuccessForm from "../../../components/user/settings/GenericSuccessForm";
import FormWrapper from "../../FormWrapper";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useUserContext } from "../../../api/hooks";

function SSHKeyDelete(props: DefaultProps) {
    const navigate = useNavigate();
    const { profile } = useUserContext();

    useEffect(() => {
        if (profile.hasResponded && !profile.isAuthenticated) {
            navigate("/account/login");
        }
    }, [profile.isAuthenticated, navigate, profile.hasResponded]);

    return (
        <div id="sshpage" className={props.className}>
            <FormWrapper>
                { /* The key below is a hacky fix but the data is so deeply nested that react wont rerender, so putting a key forces a rerender... */}
                <GenericSuccessForm successText="Your SSH Key has been deleted successfully." buttonText="BACK TO SSH SETTINGS" link="/account/sshkey" />
            </FormWrapper>
        </div>
    )
}
export default styled(SSHKeyDelete)`
.card {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.card-helper {
    display: inline-block;
    height: 70px;
    vertical-align: middle;
}
.helper {
    display: inline-block;
    height: 100px;
    vertical-align: middle;
}
@media (max-width: 600px) {
    .helper {
        display: none;
        height: 0;
    }
    .settings-form {
        width: 100% !important;
        box-shadow: none !important;
    }
}
`;