import { Box, Grid } from "@mui/material";
import styled from "styled-components";

function FormWrapper(props: FormWrapperProps) {
  return (
    <Box position="relative" className={props.className}>
      <Box className="heightAdjust" alignItems="center" display="flex">
        <Grid container justifyContent="center">
          <Grid item lg={props.noWidthLimit ? undefined : 6} md={props.noWidthLimit ? undefined : 8} sm={props.noWidthLimit ? undefined : 10} xs={12} className="maxHeight" justifyContent="center">
            {props.children}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
export default styled(FormWrapper)`
.maxwidth {
  max-width: 500px !important;
}
.heightAdjust {
  min-height: 90vh;
  @media (min-height: 2000px) {
    min-height: 95vh;
  }
}
`;