import { Box, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Tooltip, IconButton } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";
import { ReactComponent as SecdimLogo } from "../../../../assets/short-logo-without-beta.svg"
import * as Styled from './SSHKeySelectionGitlabOrGithubStyles'


function SSHKeySelectionGitlabOrGithub(props: SSHKeyUpdateProps) {
	const navigate = useNavigate();
	const sshKeyList = JSON.parse(sessionStorage.getItem("sshKeyList")!)
	const [key, setKey] = useState(sshKeyList[0].trim())
	function handleSSHKeyRadioListener(e: any) {
		setKey(e.target.value.trim())
	}
	function handleSSHKeySelection() {
		props.handler({ key: key });
	}
	return (
		<form id="sshkeyform" className={props.className}>
			<Styled.CustomPaper>
				<Box mt={0} display='flex' justifyContent="center" pr={2}>
					<SecdimLogo height={40} width={40} className="secdimlogo mr-2" onClick={() => navigate("/account/settings")} />
					<Typography id="signInText" variant="h6" component="h1" className="secdimlogo">SSH Public Key</Typography>
				</Box>
				<Box mt={5} />
				<Box display='flex' justifyContent="center">
					{/* eslint-disable */
						sshKeyList.length >= 1 && sshKeyList[0] !== "" ?
							<FormControl>
								{/* eslint-enable */}
								<FormLabel id="demo-radio-buttons-group-label">
									<Typography variant="subtitle1" id="formlabeltext">Please choose your default SSH public key:</Typography>
									<Tooltip className="formlabeltooltip" title="These are the imported SSH public keys. Choose your default SSH public key (~/.ssh/id_rsa.pub)">
										<IconButton>
											<HelpIcon sx={{ fontSize: "1.3rem" }} />
										</IconButton>
									</Tooltip>
								</FormLabel>
								<RadioGroup
									onChange={handleSSHKeyRadioListener}
									value={key}
									aria-labelledby="demo-radio-buttons-group-label"
									defaultValue="female"
									name="radio-buttons-group"
								>
									{sshKeyList.slice(0, 8).filter((sshKey: string) => sshKey !== "").map((sshKey: string) => {
										return <FormControlLabel key={sshKey} value={sshKey} id="radioFormControlLabel" control={<Radio />} label={sshKey.slice(0, 55)} />
									})}
								</RadioGroup>
							</FormControl> : <>There are no SSH public keys available</>
					}
				</Box>
				<Box display="flex" justifyContent="center" sx={{ margin: '48px 0px 20px' }}>
					<Styled.SelectButton fullWidth onClick={handleSSHKeySelection}>
						Select
					</Styled.SelectButton>
					<Styled.CancelButton fullWidth variant='outlined' onClick={() => navigate(`/account/sshkey`)}>
						Cancel
					</Styled.CancelButton>
				</Box>
			</Styled.CustomPaper>
		</form>
	)
}
export default styled(SSHKeySelectionGitlabOrGithub)`
label#radioFormControlLabel>span.MuiFormControlLabel-label {  
  inline-size: 95%;
  overflow-wrap: break-word;
}
label#radioFormControlLabel {
  margin-bottom: 8px;
  max-width: 100%;
}
label#radioFormControlLabel>span.Mui-checked {
  color: #55C1E2 !important;
}
#formlabeltext {
  float: left;
  margin-right: 5px !important;
}
.formlabeltooltip {
  position: absolute;
  top: 4px;
  padding: 0;
  color: #55C1E2 !important;
}
#demo-radio-buttons-group-label {
  color: black !important;
  margin-bottom: 10px;
}
.secdimlogo {
  vertical-align: middle;
  display: inline-block;
}
.mr-2 {
  margin-right: 10px;
}
.flex-1 {
  flex: 1;
}
.translateBack {
  transform: translateX(-25px)
}
#ErrorText {
  color: #ff0000 !important;
  font-weight: 100 !important;
  text-align: left;
  font-size: medium;
}
#signInText {
  font-weight: 400 !important;
}
#signinbtn {
  background-color: #55C1E2 !important;
  color: #FFF !important;
}
.secdimLink {
  color: #55C1E2 !important;
  cursor: pointer;
} 
@media (max-width: 500px) {
  .translateBack {
    transform: initial !important;
  }
}
`;

/* Material UI Styling */

// const CssTextField = withStyles({
//   root: {
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderColor: '#C4C4C4',
//       },
//       '&:hover fieldset': {
//         borderColor: '#000000',
//       },
//       '&.Mui-focused fieldset': {
//         borderColor: '#55C1E2',
//       },
//     },
//   },
// })(TextField);

// const CssErrorTextField = withStyles({
//   root: {
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderColor: '#ff0000',
//       },
//       '&:hover fieldset': {
//         borderColor: '#ff0000',
//       },
//       '&.Mui-focused fieldset': {
//         borderColor: '#ff0000',
//       },
//     },
//   },
// })(TextField);



