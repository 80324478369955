import { TextField, Container } from '@mui/material'
import { styled } from '@mui/material/styles'

export const PageContainer = styled(Container)({
	flex: '1 0 auto',
	display: 'flex',
	flexDirection: 'column',
	minHeight: '100vh',
})

export const CopyBoxText = styled(TextField)({
	width: '100%',
	border: 'none',
})
