import Footer from '../Footer/Footer'
import * as Styled from './PageStyles'

function Page({ className, page }: PageProps) {
	return <Styled.PageContainer className={className}>
		{page}
		<Footer />
	</Styled.PageContainer>
}

export default Page
