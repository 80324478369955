import { Box, Typography, Table, TableBody, useMediaQuery, Grid, Fade, Modal, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as SecdimLogo } from "../../../assets/short-logo-without-beta.svg"
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import * as Styled from "./UserSettingsStyles"
import { useUserContext } from "../../../api/hooks";

function SettingsForm(props: SettingsProps) {
	const [isSignUpButtonLoading, setSignUpButtonLoading] = useState(false);
	const { handleSubscriptionRedirect } = props
	const { setProfile } = useUserContext()

	const navigate = useNavigate();
	const [showOverlay, setShowOverlay] = useState(false);
	const isMobile = useMediaQuery('(max-width: 500px)');

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: isMobile ? 300 : 450,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 3,
	};

	const client = useQueryClient()

	function HandleSignout() {
		setProfile({
			hasResponded: true,
			isAuthenticated: false,
			username: '',
			isSubscribed: false,
		})
		localStorage.clear()
		client.clear();
		sessionStorage.clear();
		navigate("/account/logout");
	}

	function ToggleOverlay() {
		if (showOverlay) { setShowOverlay(false); }
		else { setShowOverlay(true); }
	}

	const onClickManageSubscription = () => {
		setSignUpButtonLoading(true)
		handleSubscriptionRedirect()
	}

	return (
		<form id="settingsform" className={props.className}>
			<Styled.CustomPaper>
				<Box p={3}>
					<Box mt={0} display='flex' justifyContent="center" pr={2}>
						<SecdimLogo height={40} width={40} className="secdimlogo mr-2" />
						<Typography id="signInText" variant="h6" component="h1" className="secdimlogo">Settings</Typography>
					</Box>
					<Box mt={2} display='flex' justifyContent="center" pr={2}>
						<Typography id="signInText" variant="h6" align="center" className="secdimlogo">{props.username ? `Hello ${props.username}` : <></>}</Typography>
					</Box>
					<Box mt={2} />
					<Box my={2} mt={3}>
						<Styled.SettingsButton fullWidth variant="contained" onClick={() => navigate(`/${props.username}`)}>
							<Box py={2}>
								Your Profile
							</Box>
						</Styled.SettingsButton>
					</Box>
					<Box my={2} sx={{ cursor: 'pointer' }}>
						{isSignUpButtonLoading ? <Styled.CustomLoadingButton onClick={onClickManageSubscription} loading>
							<Typography>Please Wait</Typography>
						</Styled.CustomLoadingButton> : <Styled.SettingsButton fullWidth variant="contained" onClick={onClickManageSubscription}>
							<Box py={2}>
								MANAGE SUBSCRIPTION
							</Box>
						</Styled.SettingsButton>}
					</Box>
					<Box my={2} mt={3}>
						<Styled.SettingsButton fullWidth variant="contained" onClick={() => navigate("/account/change-password")}>
							<Box py={2}>
								CHANGE PASSWORD
							</Box>
						</Styled.SettingsButton>
					</Box>
					<Box my={2} mt={3}>
						<Styled.SettingsButton fullWidth variant="contained" onClick={() => navigate("/account/sshkey")}>
							<Box py={2}>
								SSH KEY
							</Box>
						</Styled.SettingsButton>
					</Box>
					<Box my={2} mt={3} mb={0}>
						<Table ><TableBody >
							<TableRow>
								<Styled.CSSTableCell className="formatTable">
									<Styled.SignoutButton variant="outlined" onClick={() => HandleSignout()}>
										<Box py={2}>
											SIGN OUT
										</Box>
									</Styled.SignoutButton>
								</Styled.CSSTableCell>
								<Styled.CSSTableCell className="formatTable" align="right">
									<Styled.CSSButton variant="text" className="nobgbtn-noborder" onClick={() => ToggleOverlay()}>
										<Styled.IconApps />
									</Styled.CSSButton>
									<Modal
										open={showOverlay}
										onClose={() => ToggleOverlay()}
										aria-describedby="modal-modal-description"
										closeAfterTransition
									>
										<Fade in={showOverlay}>
											<Styled.ModalBox sx={style} id="modal-box">
												<Box id="modal-modal-description" sx={{ mt: 2 }}>
													<Styled.CSSGrid container>
														<Grid item xs={4}>
															<Styled.CSSButton variant="contained" >
																<a href="https://learn.secdim.com" target="_blank" rel="noopener noreferrer">
																	<Box display="flex" alignItems="center" justifyContent="center">
																		<Styled.IconLearn color="primary" fontSize={isMobile ? "small" : "large"} />
																	</Box>
																</a>
															</Styled.CSSButton>
															<Styled.CSSTypography align="center">LEARN</Styled.CSSTypography>
														</Grid>
														<Grid item xs={4}>
															<Styled.CSSButton variant="contained">
																<a href="https://discuss.secdim.com" target="_blank" rel="noopener noreferrer">
																	<Box display="flex" alignItems="center" justifyContent="center">
																		<Styled.IconDiscuss color="primary" fontSize={isMobile ? "small" : "large"} />
																	</Box>
																</a>
															</Styled.CSSButton>
															<Styled.CSSTypography align="center">DISCUSS</Styled.CSSTypography>
														</Grid>
														<Grid item xs={4}>
															<Styled.CSSButton variant="contained">
																<a href="https://play.secdim.com" target="_blank" rel="noopener noreferrer">
																	<Box display="flex" alignItems="center" justifyContent="center">
																		<Styled.IconPlay color="primary" fontSize={isMobile ? "small" : "large"} />
																	</Box>
																</a>
															</Styled.CSSButton>
															<Styled.CSSTypography align="center">PLAY</Styled.CSSTypography>
														</Grid>
													</Styled.CSSGrid>
												</Box>
											</Styled.ModalBox>
										</Fade>
									</Modal>
								</Styled.CSSTableCell>
							</TableRow>
						</TableBody></Table>
					</Box>
				</Box>
			</Styled.CustomPaper>
		</form>
	)
}
export default styled(SettingsForm)`
#signoutbtn {
  padding: 0px 24px !important;
  border-color: #ff000073 !important;
  color: #ff0000de !important;
}

#signoutbtn:hover {
  background-color: #ff00000d !important;
}

.nobgbtn {
  background-color: #FFF !important; 
}
.nobgbtn-noborder {
  color: #55C1E2 !important;
  background-color: #FFF !important;
  border-color: rgb(0, 0, 0)
}
.formatTable{
  border: none;
  boxShadow: none;
  border-bottom: 0px solid rgba(0, 0, 0, 0) !important;
  padding: 0px;
}
.ErrorText {
  color: #ff0000 !important;
}
.secdimlogo {
  vertical-align: middle;
  display: inline-block;
}
.mr-2 {
  margin-right: 10px;
}
.flex-1 {
  flex: 1;
}
.translateBack {
  transform: translateX(-25px)
}
#ErrorText {
  color: #ff0000 !important;
}
#signInText {
  font-weight: 400 !important;
  margin-top: 5px;
}
#signinbtn {
  border: 1px solid #55C1E2;
  background-color: #55C1E2 !important;
  color: #FFF !important;
}
.secdimLink {
  color: #55C1E2 !important;
  cursor: pointer;
}
@media (max-width: 500px) {
  .translateBack {
    transform: initial !important;
  }
}
`;