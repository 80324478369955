import {
	createContext,
	useContext,
	useEffect,
	useLayoutEffect,
	useRef
} from 'react'

export function useInterval(callback: () => void, delay: number | null) {
	const savedCallback = useRef(callback)

	// Remember the latest callback if it changes.
	useLayoutEffect(() => {
		savedCallback.current = callback
	}, [callback])

	// Set up the interval.
	useEffect(() => {
		// Don't schedule if no delay is specified.
		// Note: 0 is a valid value for delay.
		if (!delay && delay !== 0) {
			return
		}

		// Execute first call immediately
		savedCallback.current()

		// Set the delayed interval execution
		const id = setInterval(() => savedCallback.current(), delay)
		// eslint-disable-next-line consistent-return
		return () => clearInterval(id)
	}, [delay])
}

export const UserDetailsContext = createContext({
	showUserDetailsUpdateDialog: false,
	user: {
		first_name: "",
		last_name: "",
		profile_image: "",
		username: "",
		date_joined: "",
		location: "",
	},
	// eslint-disable-next-line no-unused-vars
	setShowUserDetailsUpdateDialog: (showUserDetailsUpdateDialog: boolean) => {
	},
	// eslint-disable-next-line no-unused-vars
	setUser: (user: {
		first_name: string,
		last_name: string,
		profile_image: string,
		username: string,
		date_joined: string,
		location: string,
	}) => {
	}
})

export function useUserDetailsContext() {
	return useContext(UserDetailsContext)
}

export const UserContext = createContext({
	profile: {
		hasResponded: false,
		isAuthenticated: false,
		username: '',
		isSubscribed: false,
	},
	// eslint-disable-next-line no-unused-vars
	setProfile: (profile: {
		hasResponded: boolean,
		isAuthenticated: boolean,
		username: string,
		isSubscribed: boolean,
	}) => { },
})

export function useUserContext() {
	return useContext(UserContext)
}