import styled from "styled-components";
import SettingsSSHKeyForm from "../../components/user/settings/SettingsSSHKeyForm";
import FormWrapper from "../FormWrapper";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { idRequestHandler } from "../../api/requestHandler";
import { useQueryClient } from "@tanstack/react-query";
import { useUserContext } from "../../api/hooks";

function SettingsSSHKey(props: DefaultProps) { /* eslint-disable  @typescript-eslint/no-unused-vars */
    const dummy = useQueryClient; /* eslint-enable  @typescript-eslint/no-unused-vars */
    const navigate = useNavigate();
    const [hasFailed, setHasFailed] = useState(false);
    const { profile } = useUserContext();

    useEffect(() => {
        if (profile.hasResponded && !profile.isAuthenticated) {
            navigate("/account/login");
        }
    }, [profile.hasResponded, profile.isAuthenticated, navigate]);

    async function handleSSHUpdate(payload: SSHKeyUpdatePayload) {
        try {
            const resp = await idRequestHandler("sshkeyUpdate", payload)
            if (resp.status === 200) {
                setHasFailed(false);
                if (payload.key === "0") {
                    navigate("/account/sshkey/delete");
                }
                else {
                    navigate("/account/sshkey/success");
                }

            } else {
                setHasFailed(true);
            }
        } catch (e: unknown) {
            setHasFailed(true);
        }
    }

    return (
        <div id="sshpage" className={props.className}>
            <FormWrapper>
                {profile.isAuthenticated && <SettingsSSHKeyForm handler={handleSSHUpdate} hasFailed={hasFailed} />}
            </FormWrapper>
        </div>
    )
}
export default styled(SettingsSSHKey)`
.card {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.card-helper {
    display: inline-block;
    height: 70px;
    vertical-align: middle;
}
.helper {
    display: inline-block;
    height: 100px;
    vertical-align: middle;
}
@media (max-width: 600px) {
    .helper {
        display: none;
        height: 0;
    }
    .settings-form {
        width: 100% !important;
        box-shadow: none !important;
    }
}
`;