import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import SSHKeyChangeForm from "../../../components/user/settings/sshkey/SSHKeyChangeForm";
import FormWrapper from "../../FormWrapper";
import { useEffect, useState } from "react";
import { idRequestHandler } from "../../../api/requestHandler";
import { useUserContext } from "../../../api/hooks";


function SSHKeyChange(props: DefaultProps) { /* eslint-disable  @typescript-eslint/no-unused-vars */
    const navigate = useNavigate()
    const [hasFailed, setHasFailed] = useState(false);
    const { profile } = useUserContext();

    useEffect(() => {
        if (profile.hasResponded && !profile.isAuthenticated) {
            navigate("/account/login");
        }
    }, [profile.isAuthenticated, navigate, profile.hasResponded]);

    async function handleSSHUpdate(payload: SSHKeyUpdatePayload) {
        try {
            const resp = await idRequestHandler("sshkeyUpdate", payload)
            if (resp.status === 200) {
                setHasFailed(false);
            } else {
                setHasFailed(true);
            }
        } catch (e: unknown) {
            setHasFailed(true);
        }
    }

    return (
        <div id="sshpage" className={props.className}>
            <FormWrapper>
                {profile.isAuthenticated && <SSHKeyChangeForm handler={handleSSHUpdate} hasFailed={hasFailed} />}
            </FormWrapper>
        </div>
    )
}
export default styled(SSHKeyChange)`
.card {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.card-helper {
    display: inline-block;
    height: 70px;
    vertical-align: middle;
}
.helper {
    display: inline-block;
    height: 100px;
    vertical-align: middle;
}
@media (max-width: 600px) {
    .helper {
        display: none;
        height: 0;
    }
    .settings-form {
        width: 100% !important;
        box-shadow: none !important;
    }
}
`;