import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { idRequestHandler } from "../../../api/requestHandler";
import SSHKeySelectionGitlabOrGithub from "../../../components/user/settings/sshkey/SSHKeySelectionGitlabOrGithub";
import FormWrapper from "../../FormWrapper";
import { useEffect } from "react";
import { useUserContext } from "../../../api/hooks";

function SSHKeySetupGitlabOrGitHub(props: DefaultProps) { /* eslint-disable  @typescript-eslint/no-unused-vars */
    const navigate = useNavigate()
    const { profile } = useUserContext();

    useEffect(() => {
        if (profile.hasResponded && !profile.isAuthenticated) {
            navigate("/account/login");
        }
    }, [profile.isAuthenticated, navigate, profile.hasResponded]);
    
    async function handleSSHUpdate(payload: SSHKeyUpdatePayload) {
        try {
            const resp = await idRequestHandler("sshkeyUpdate", payload)
            if (resp.status === 200) {
                navigate("/account/sshkey/success")
            }
        } catch (error: unknown) {
            console.error(error)
        }
    }

    return (
        <div id="sshpage" className={props.className}>
            <FormWrapper>
                { /* The key below is a hacky fix but the data is so deeply nested that react wont rerender, so putting a key forces a rerender... */}
                <SSHKeySelectionGitlabOrGithub handler={handleSSHUpdate} />
            </FormWrapper>
        </div>
    )
}
export default styled(SSHKeySetupGitlabOrGitHub)`
.card {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.card-helper {
    display: inline-block;
    height: 70px;
    vertical-align: middle;
}
.helper {
    display: inline-block;
    height: 100px;
    vertical-align: middle;
}
@media (max-width: 600px) {
    .helper {
        display: none;
        height: 0;
    }
    .settings-form {
        width: 100% !important;
        box-shadow: none !important;
    }
}
`;