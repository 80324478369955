export const urls = {
    heartbeat: {
        endpoint: 'id/account/hb/',
        type: 'GET'
    },
    register: {
        endpoint: 'id/account/register/',
        type: 'POST'
    },
    githubLogin: {
        endpoint: 'id/account/github/',
        type: 'POST'
    },
    retrieveGithubSSHKeys: {
        endpoint: 'id/account/github/ssh-keys/',
        type: 'GET'
    },
    changePassword: {
        endpoint: 'id/account/change-password/',
        type: 'POST'
    },
    companyMember: {
        endpoint: 'id/account/company-signup/',
        type: 'POST'
    },
    login: {
        endpoint: 'id/account/login/',
        type: 'POST'
    },
    userInfo: {
        endpoint: 'id/account/user/',
        type: 'GET'
    },
    updateUserInfo: {
        endpoint: 'id/account/user/info/update/',
        type: 'POST'
    },
    userSubscriptionInfo: {
        endpoint: 'id/subscription/user/',
        type: 'GET'
    },
    gitlabLogin: {
        endpoint: 'id/account/gitlab/',
        type: 'POST'
    },
    retrieveGitlabSSHKeys: {
        endpoint: 'id/account/gitlab/ssh-keys/',
        type: 'GET'
    },
    signOut: {
        endpoint: 'id/account/logout/',
        type: 'POST'
    },
    requestPasswordReset: {
        endpoint: 'id/account/password_reset/',
        type: 'POST'
    },
    validatePasswordToken: {
        endpoint: 'id/account/password_reset/validate_token/',
        type: 'POST'
    },
    sshkeyUpdate: {
        endpoint: 'id/account/set-ssh-key/',
        type: 'POST'
    },
    passwordReset: {
        endpoint: 'id/account/password_reset/confirm/',
        type: 'POST'
    },
    createCheckoutSession: {
        endpoint: 'id/subscription/create-checkout-session/',
        type: 'POST'
    },
    createPortalSession: {
        endpoint: 'id/subscription/create-portal-session/',
        type: 'POST'
    },
    products: {
        endpoint: 'id/subscription/products/',
        type: 'GET'
    },
    // play api
    userActivity: {
        endpoint: 'play/player/',
        type: 'GET'
    },
}