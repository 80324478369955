import { styled } from '@mui/material/styles'
import { Box, Typography, TextField, Grid, TableCell, Button, Paper } from '@mui/material'
import { Forum, MenuBook, PlayArrow, Apps, Visibility, VisibilityOff, Check, AccountBox } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'

export const InfoText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "isError" && prop !== "isSuccess"
})<{ isError?: boolean, isSuccess?: boolean }>(({ isError = false, isSuccess = false }) => ({
    color: isError ? '#ff0000' : isSuccess ? 'green' : 'inherit',
    textAlign: isError ? 'left' : isSuccess ? 'center' : 'inherit',
    fontSize: 'medium',
    fontWeight: 100,
}))

export const UserIcon = styled(AccountBox)({
    color: '#55C1E2',
    width: '200px',
    height: '200px'
})

export const IconButton = styled(Button)({
    cursor: "pointer",
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    outline: 'none',
    '&:hover': {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        outline: 'none',
    }

})

export const CheckIcon = styled(Check)({

})

export const VisibilityIcon = styled(Visibility)({

})

export const VisibilityOffIcon = styled(VisibilityOff)({

})

export const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#C4C4C4',
        },
        '&:hover fieldset': {
            borderColor: '#000000',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#55C1E2',
        },
    },
})

export const ActionButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "invert"
})<{ invert?: boolean }>(({ invert = false }) => ({
    border: '1px solid #55C1E2',
    backgroundColor: invert ? '#FFF' : '#55C1E2',
    color: invert ? '#55C1E2' : '#FFF',
    '&:hover': {
        backgroundColor: invert ? '#55C1E2' : '#FFF',
        color: invert ? '#FFF' : '#55C1E2',
    },
    '&:disabled': {
        backgroundColor: '#5e819d',
        color: '#FFF',
    }
}))


export const ContinueButton = styled(Button)(() => ({
    border: '1px solid #55C1E2 !important',
    backgroundColor: '#55C1E2 !important',
    color: '#FFF !important',
    padding: '20px 8px !important',
    '&:hover': {
        border: '1px solid #55C1E2',
        backgroundColor: '#FFF !important',
        color: '#55C1E2 !important',
    },
}))

export const SkipButton = styled(Button)(() => ({
    border: '1px solid #55C1E2',
    backgroundColor: '#FFF !important',
    color: '#55C1E2 !important',
    padding: '20px 8px !important',
    '&:hover': {
        backgroundColor: '#55C1E2 !important',
        color: '#FFF !important',
        borderColor: '#55C1E2 !important'
    },
}))

export const SettingsButton = styled(Button)({
    border: '1px solid #55C1E2',
    backgroundColor: '#55C1E2',
    color: '#FFF',
    '&:hover': {
        border: '1px solid #55C1E2',
        backgroundColor: '#FFF',
        color: '#55C1E2',
    }
})

export const SignoutButton = styled(Button)({
    padding: '0px 24px',
    borderColor: '#ff000073',
    backgroundColor: '#FFF',
    color: '#ff0000de',
    '&:hover': {
        color: '#FFF',
        backgroundColor: '#ff0000de',
        borderColor: '#ff000073',
    }
})

export const CSSTypography = styled(Typography)({
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingRight: 4,
})

export const CSSGrid = styled(Grid)({
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingLeft: 15,
    paddingRight: 20,
})

export const CSSTableCell = styled(TableCell)({
    borderBottom: 'none',
    padding: 5,
})

export const CSSButton = styled(Button)({
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    outline: 'none',
    '&:hover': {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        outline: 'none',
    }
})

export const ModalBox = styled(Box)({
    margin: 0
})

export const IconApps = styled(Apps)({
    color: '#55C1E2',
    width: '50px',
    height: '50px',
    '&:hover': {
        color: '#34b5dc',
    }
})

export const IconLearn = styled(MenuBook, {
    shouldForwardProp: (prop) => prop !== "fontSize"
})<{ fontSize: string }>(({ fontSize }) => ({
    color: '#55C1E2',
    width: fontSize === "large" ? '100px' : '50px',
    height: fontSize === "large" ? '100px' : '50px',
    '&:hover': {
        color: '#34b5dc',
    }
}))

export const IconDiscuss = styled(Forum, {
    shouldForwardProp: (prop) => prop !== "fontSize"
})<{ fontSize: string }>(({ fontSize }) => ({
    color: '#55C1E2',
    width: fontSize === "large" ? '100px' : '50px',
    height: fontSize === "large" ? '100px' : '50px',
    '&:hover': {
        color: '#34b5dc',
    }
}))

export const IconPlay = styled(PlayArrow, {
    shouldForwardProp: (prop) => prop !== "fontSize"
})<{ fontSize: string }>(({ fontSize }) => ({
    color: '#55C1E2',
    width: fontSize === "large" ? '100px' : '50px',
    height: fontSize === "large" ? '100px' : '50px',
    '&:hover': {
        color: '#34b5dc',
    }
}))

export const CustomPaper = styled(Paper)(({ theme }) => ({
    width: '450px',
    margin: '0 auto'
}))

export const UserDetailsUpdateButtons = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '24px'
}))

export const CustomLoadingButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: `#55C1E2 !important`,
    color: `${theme.palette.common.white} !important`,
    width: '100%',
    height: '70.5px !important',
    cursor: 'pointer !important',
    border: `1px solid #55C1E2`,
    padding: '5px 15px !important',

    '&:hover': {
        backgroundColor: theme.palette.common.white,
        color: `${theme.palette.primary.main} !important`,
        border: `1px solid ${theme.palette.primary.main}`,
        'div': {
            color: `${theme.palette.primary.main} !important`,
        },
    },
    '&>div': {
        color: `${theme.palette.common.white} !important`,
        left: '66%',
        top: '39%'
    },
    '&>p': {
        fontSize: '0.875rem',
        lineHeight: '1.75',
        letterSpacing: '0.875px',
        fontWeight: 500,
    },
    '&>div>span': {
        width: '0.875rem !important',
        height: '0.875rem !important'
    },
}))

export const PasswordStrength = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isChecked"
})<{ isChecked: boolean }>(({ isChecked = false }) => ({
    display: 'flex',
    color: isChecked ? '#55C1E2' : 'red',
    marginTop: '3px'
}))

export const PasswordStrengthText = styled(Typography)({
    marginLeft: '6px'
})



