import { AxiosError } from "axios";
import { useState } from "react";
import styled from "styled-components";
import { comprehendDjangoError } from "../../api/queryHandlers";
import { idRequestHandler } from "../../api/requestHandler";
import GenericSuccess from "../../components/user/settings/GenericSuccessForm";
import SettingsPasswordForm from "../../components/user/settings/SettingsPasswordForm";
import FormWrapper from "../FormWrapper";

function SettingsPassword(props: DefaultProps) {
    const [error, setError] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);

    function SignUp(e: { old_password: string, new_password: string }) {
        idRequestHandler('changePassword', e).then((resp) => {
            setShowSuccess(true);
        }).catch((err) => {
            const reason = comprehendDjangoError((err as AxiosError).response?.data)
            setError(reason)
        })
    }
    return (
        <div id="password" className={props.className}>
            {!showSuccess
                ? <FormWrapper>
                    <SettingsPasswordForm handler={SignUp} error={error} />
                </FormWrapper>
                : <FormWrapper><GenericSuccess successText="Your password has been changed successfully." buttonText="Login" link="/" /></FormWrapper>
            }
        </div>
    )
}
export default styled(SettingsPassword)`
.card {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.card-helper {
    display: inline-block;
    height: 70px;
    vertical-align: middle;
}
.helper {
    display: inline-block;
    height: 100px;
    vertical-align: middle;
}
@media (max-width: 600px) {
    .helper {
        display: none;
        height: 0;
    }
    .settings-form {
        width: 100% !important;
        box-shadow: none !important;
    }
}
`;