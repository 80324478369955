import axios, { AxiosResponse, Method } from "axios";
import { OperationResponse, operations } from "./operations";
import { urls } from "./urls";

export const getCSRFToken = () => {
  const cookies = document.cookie.split("; ");
  const csrfTokenIndex = cookies.findIndex((item) =>
    item.startsWith("id_csrftoken")
  );

  if (csrfTokenIndex >= 0) {
    return cookies[csrfTokenIndex].replace("id_csrftoken=", "");
  }

  return "";
};

export async function idRequestHandler<T extends operations>(
  operation: T,
  data?: any,
  token?: string,
  captcha?: string,
  appendURL?: string
): Promise<AxiosResponse<OperationResponse[T]>> {
  const endpointDetails = urls[operation];
  const csrfToken = getCSRFToken();
  if (endpointDetails) {
    let url = `${process.env.REACT_APP_BASE_API_URL}${endpointDetails.endpoint}`;
    if (appendURL) {
      url = `${process.env.REACT_APP_BASE_API_URL}${endpointDetails.endpoint}${appendURL}`;
    }
    const headers: any = {
      Accept: "application/json",
      "X-CSRFToken": csrfToken,
      "Content-type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    if (captcha) {
      data["recaptcha_response"] = captcha;
    }
    try {
      return await axios({
        method: endpointDetails.type as Method,
        withCredentials: true,
        headers,
        url: url,
        data,
      });
    } catch (err) {
      throw err;
    }
  } else {
    throw new Error("Invalid Operation");
  }
}

export async function playRequestHandler<T extends operations>(
  operation: T,
  data?: any,
  token?: string,
  captcha?: string,
  appendURL?: string
): Promise<AxiosResponse<OperationResponse[T]>> {
  const endpointDetails = urls[operation];
  const csrfToken = getCSRFToken();
  if (endpointDetails) {
    let url = `${process.env.REACT_APP_PLAY_API_URL}/${endpointDetails.endpoint}`;
    if (appendURL) {
      url = `${url}${appendURL}`;
    }
    const headers: any = {
      Accept: "application/json",
      "X-CSRFToken": csrfToken,
      "Content-type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    if (captcha) {
      data["recaptcha_response"] = captcha;
    }
    try {
      return await axios({
        method: endpointDetails.type as Method,
        withCredentials: true,
        headers,
        url: url,
        data,
      });
    } catch (err) {
      throw err;
    }
  } else {
    throw new Error("Invalid Operation");
  }
}
