import React, { lazy } from "react";
import {
    Routes,
    Route,
    BrowserRouter
} from "react-router-dom";
import { useOpenID } from "./api/queryHandlers";
import Page from "./shared/components/Page/Page";
import Settings from "./pages/user/Settings";
import SettingsSSHKey from "./pages/user/SettingsSSHKey";
import SSHKeySetupGitlabOrGitHub from "./pages/user/sshkey/SSHKeySetupGitlabOrGitHub";
import SSHKeyChange from "./pages/user/sshkey/SSHKeyChange";
import SSHKeyDelete from "./pages/user/sshkey/SSHKeyDelete";
import SSHKeySuccess from "./pages/user/sshkey/SSHKeySuccess";
import SettingsPassword from "./pages/user/SettingsPassword";

const Signout = lazy(() => import("./pages/Signout"));
const LoginPaths = lazy(() => import('./pages/LoginPaths'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Register = lazy(() => import('./pages/Register'));
const GithubRegister = lazy(() => import('./pages/GithubRegister'));
const GitlabRegister = lazy(() => import('./pages/GitlabRegister'));
const ForgotPass = lazy(() => import("./pages/ForgotPass"));
const ChangePass = lazy(() => import("./pages/ChangePass"));
const SuccessPass = lazy(() => import("./pages/SuccessPass"));
const SuccessRegistration = lazy(() => import("./pages/SuccessRegistration"));
const Subscription = lazy(() => import("./pages/Subscription"));
const SubscriptionPortal = lazy(() => import("./pages/SubscriptionPortal"));
const Trial = lazy(() => import("./pages/Trial"));
const SuccessSub = lazy(() => import("./pages/SuccessSub"));
const UserProfile = lazy(() => import("./pages/UserProfile/UserProfile"));

function App() {
    useOpenID();

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Page page={<LoginPaths />} />} />
                <Route path="/account/login/*" element={<Page page={<LoginPaths />} />} />
                <Route path="/account/register" element={<Page page={<Register />} />} />
                <Route path="/account/register/github" element={<Page page={<GithubRegister />} />} />
                <Route path="/account/register/gitlab" element={<Page page={<GitlabRegister />} />} />
                <Route path="/account/logout" element={<Page page={<Signout />} />} />
                <Route path="/account/forgot-password" element={<Page page={<ForgotPass />} />} />
                <Route path="/account/reset-password" element={<Page page={<ChangePass />} />} />
                <Route path="/account/change-password/success" element={<Page page={<SuccessPass />} />} />
                <Route path="/account/register/success" element={<Page page={<SuccessRegistration />} />} />
                <Route path="/account/settings" element={<Page page={<Settings />} />} />
                <Route path="/account/sshkey" element={<Page page={<SettingsSSHKey />} />} />
                <Route path="/account/sshkey/gitlab" element={<Page page={<SSHKeySetupGitlabOrGitHub />} />} />
                <Route path="/account/sshkey/github" element={<Page page={<SSHKeySetupGitlabOrGitHub />} />} />
                <Route path="/account/sshkey/change" element={<Page page={<SSHKeyChange />} />} />
                <Route path="/account/sshkey/delete" element={<Page page={<SSHKeyDelete />} />} />
                <Route path="/account/sshkey/success" element={<Page page={<SSHKeySuccess />} />} />
                <Route path="/account/change-password" element={<Page page={<SettingsPassword />} />} />
                <Route path="/subscription/plan" element={<Page page={<Subscription />} />} />
                <Route path="/subscription/portal" element={<Page page={<SubscriptionPortal />} />} />
                <Route path="/subscription/trial" element={<Page page={<Trial />} />} />
                <Route path="/subscription/success" element={<Page page={<SuccessSub />} />} />
                <Route path="/:username" element={<Page page={<UserProfile />} />} />
                <Route path="*" element={<Page page={<NotFound />} />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;