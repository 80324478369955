import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { idRequestHandler, playRequestHandler } from "./requestHandler";

export function saveOpenIDToLocalStorage() {
    if (sessionStorage.getItem('openid')) {
        const openID: OpenID = JSON.parse(sessionStorage.getItem('openid')!!) as OpenID
        localStorage.setItem('openid', JSON.stringify(openID))
        return openID;
    } else {
        throw Error("Cannot construct openid")
    }
}

export function useOpenID(): OpenID | undefined {
    try {
        const urlSearchParams = new URLSearchParams(decodeURIComponent(window.location.search));
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params?.next) {
            const temp = params?.next.split('?');
            const next = temp[0];
            const client_id = temp[1];
            const nonce = params?.nonce;
            const redirect_uri = params?.redirect_uri;
            const response_type = params?.response_type;
            const scope = params?.scope;
            const state = params?.state;
            const openidobj: OpenID = {
                next, nonce, redirect_uri, client_id, response_type, scope, state
            }
            sessionStorage.setItem('openid', JSON.stringify(openidobj));
            urlSearchParams.delete('next')
            urlSearchParams.delete('nonce')
            urlSearchParams.delete('redirect_uri')
            urlSearchParams.delete('scope')
            urlSearchParams.delete('state')
        }
        if (localStorage.getItem('openid')) {
            // Take precedence
            const openID: OpenID = JSON.parse(localStorage.getItem('openid')!!) as OpenID
            localStorage.removeItem('openid')
            return openID;
        }
        if (sessionStorage.getItem('openid')) {
            const openID: OpenID = JSON.parse(sessionStorage.getItem('openid')!!) as OpenID
            return openID;
        }
        return
    } catch {
        console.error("likely not openid as I had a exception trying to read params")
    }
}

export function usePublicUserDetails(username: string): UseQueryResult<AxiosResponse<User>> {
    return useQuery({
        queryKey: [`userInfo`],
        queryFn: async (): Promise<AxiosResponse<User>> => {
            const resp = await idRequestHandler("userInfo", {}, undefined, undefined, `${username}/info/`)
            return resp
        },
        refetchOnWindowFocus: false,
    })
}

function titleCaseWord(word: any) {
    if (!word) return word;
    return `${word}`[0].toUpperCase() + `${word}`.substr(1);
}
export function comprehendDjangoError(errors: any): string {
    let finalErrorStr = ""
    for (const key in errors) {
        if (key === "non_field_errors") {
            finalErrorStr += `${titleCaseWord(errors[key])}\n`
        } else {
            finalErrorStr += `${titleCaseWord(key)}: ${titleCaseWord(errors[key])}\n`
        }
    }
    return finalErrorStr
}

export function useProducts() {
    return useQuery({
        queryKey: ['products'],
        queryFn: async(): Promise<AxiosResponse<Product[]>> => idRequestHandler('products', { }),
        gcTime: 60000,
        staleTime: 60000
    })
}


export function useUserActivity(username: string): UseQueryResult<AxiosResponse<UserActivity>> {
    return useQuery({
        queryKey: [`userActivity`],
        queryFn: async (): Promise<AxiosResponse<User>> => {
            const resp = await playRequestHandler("userActivity", {}, undefined, undefined, `${username}/activity`)
            return resp
        },
        refetchOnWindowFocus: false,
    })
}