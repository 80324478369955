import { Box, CircularProgress } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as SecDimLogo } from "../../../assets/logo.svg"
function Loading(props: LoadingProps) {
    return (
        <div className={props.className}>
            <Box width="100vw" overflow="hidden" height="100vh" alignItems="center" position="absolute" top={0} left={0} justifyContent="center" display="flex" className={props.overlay ? "overlaybg" : ""}>
                <Box mr={5} id="translateItDown">
                    <SecDimLogo />
                </Box>
                <CircularProgress />
            </Box>
        </div>
    )
}

export default styled(Loading)`
#translateItDown {
    transform: translateY(6px)
}
.MuiCircularProgress-colorPrimary {
    color: #55c1e2;
}
.overlaybg {
    background-color: rgba(255,255,255,0.5) !important;
    z-index: 2;
}
`;