import { Button, Paper } from "@mui/material";
import styled from "styled-components";

export const SelectButton = styled(Button)(() => ({
	border: '1px solid #55C1E2 !important',
	backgroundColor: '#55C1E2 !important',
	color: '#FFF !important',
	marginRight: '10px !important',
	// width: '79%',
	'&:hover': {
		border: '1px solid #55C1E2',
		backgroundColor: '#FFF !important',
		color: '#55C1E2 !important',
	},
	padding: '12px 5px !important'
}))

export const CancelButton = styled(Button)(() => ({
	border: '1px solid #55C1E2',
	backgroundColor: '#FFF !important',
	color: '#55C1E2 !important',
	marginLeft: '10px !important',
	padding: '12px 5px !important',
	'&:hover': {
		backgroundColor: '#55C1E2 !important',
		color: '#FFF !important',
		borderColor: '#55C1E2 !important'
	},
}))

export const CustomPaper = styled(Paper)(({ theme }) => ({
	display: 'block',
	margin: '0 auto',
	width: '480px',
	padding: "30px !important"
}))