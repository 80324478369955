import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import styled from "styled-components"

function AppWrapper(props: AppWrapperProps) {
    const client = new QueryClient();
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
        >
            <QueryClientProvider client={client}>
                {props.children}
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </GoogleReCaptchaProvider>
    )
}

export default styled(AppWrapper)`
`;